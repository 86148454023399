<template>
  <div>
    <h1></h1>
    <v-menu left offset-y content-class="menu-content__list">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="menuList d-flex flex-column" dense>
        <v-list-item @click="sendPeople" class="px-4 d-flex align-center">
          <v-icon class="mr-3">mdi-account-multiple-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title>People</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$route.name !== 'Spam'"
          @click="archiveDialog = !archiveDialog" :disabled="disableItems"
          class="px-4 d-flex align-center"
        >
          <v-icon class="mr-3">mdi-archive-arrow-down-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ isArchive ? 'Remove from archive' : 'Archive' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="spamDialog = !spamDialog" :disabled="disableItems" class="px-4 d-flex align-center">
          <v-icon class="mr-3">mdi-alert-octagon-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ isSpam ? 'Unmark as Spam' : 'Mark as Spam'}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="blockDialog = !blockDialog" :disabled="disableItems" class="px-4 d-flex align-center">
          <v-icon class="mr-3">mdi-cancel</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ isBlocked ? 'Unblock Number' : 'Block Number'}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="toggleDeleteDialog" :disabled="disableItems" class="px-4 d-flex align-center">
          <v-icon class="mr-3">mdi-delete-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Permanently delete {{ $route.name === 'Voicemails' ? 'voicemail': 'call' }}?
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text> It's permanent and cannot be undone.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="deleteDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="danger"
            class="px-5 py-5 white--text"
            elevation="0"
            :loading="loading"
            @click="$route.name === 'Voicemails' ? removeVoicemail() : removeNumber()"
            >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Conversation Dialog -->
    <v-dialog v-model="deleteConversationDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Delete permanently
          <v-spacer></v-spacer>
          <v-btn @click="deleteConversationDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Deleting a conversation with a person will remove the entire conversation. It's permanent and cannot be undone.
          <v-checkbox label="I understand" v-model="deleteConversationCheckbox"></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="deleteConversationDialog = false"
          >Cancel
          </v-btn>
          <v-btn
            small
            color="danger"
            class="px-5 py-5 white--text"
            elevation="0"
            :disabled="!deleteConversationCheckbox"
            :loading="loading"
            @click="removeChat"
          >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Archive Dialog -->
    <v-dialog v-model="archiveDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          {{ isArchive ? 'Remove this call from archive?' : 'Archive this call?' }}
          <v-spacer></v-spacer>
          <v-btn @click="archiveDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="archiveDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            class="px-5 py-5 text-none"
            elevation="0"
            @click="markArchiveNumber"
            :loading="loading"
            >{{ isArchive ? 'Continue' : 'Archive' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Restore Dialog -->
    <v-dialog v-model="restoreDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Restore this number?
          <v-spacer></v-spacer>
          <v-btn @click="restoreDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="restoreDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="restoreDialog = false"
            >Restore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Mark to spam Dialog -->
    <v-dialog v-model="spamDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          {{ isSpam ? 'Unmark as Spam this number?' : 'Mark as Spam this number?'}}
          <v-spacer></v-spacer>
          <v-btn @click="spamDialog = false" small icon>
            <v-icon size="11.67" color="#5F6368">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="0"
            width="90px"
            height="36px"
            small
            outlined
            @click="spamDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            elevation="0"
            width="80px"
            height="36px"
            @click="numberSpam"
            :loading="loading"
            >{{ isSpam ? 'Unmark' : 'Mark'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Block Dialog -->
    <v-dialog v-model="blockDialog" width="512">
      <v-card>
        <v-card-title class="body-1">
          {{ isBlocked ? 'Unblock' : 'Block'}} this number?
          <v-spacer></v-spacer>
          <v-btn @click="blockDialog = false" small icon>
            <v-icon size="11.67">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="isBlocked" style="padding-bottom:4px">
          You will receive messages from this number, and they'll see messages
          you send if you're both in a group message.
        </v-card-text>
        <v-card-text v-else style="padding-bottom:4px">
          You won't receive messages from this number, but they'll see messages
          you send if you're both in a group message.
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            width="90px"
            height="36px"
            elevation="0"
            outlined
            @click="blockDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            width="80px"
            height="36px"
            elevation="0"
            :loading="loading"
            @click="isBlocked ? numberUnblock() : numberBlock()"
            >{{ isBlocked ? 'Unblock' : 'Block'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'ActionsMenu',
  data: () => ({
    loading: false,
    profile: {},
    deleteDialog: false,
    deleteConversationDialog: false,
    deleteConversationCheckbox: false,
    spamDialog: false,
    restoreDialog: false,
    archiveDialog: false,
    blockDialog: false,
    isSpam: false,
    blockList: []
  }),
  computed: {
    ...mapState({
      showPeopleModal: (state) => state.showPeople
    }),
    ...mapGetters('calls', ['callItem', 'voicemailItem', 'messageItem']),
    ...mapGetters('messages', ['messageItem']),
    disableItems () {
      return this.$route.name === 'Messages' && !this.actionsMenuItem.num
    },
    actionsMenuItem () {
      return this.$route.name === 'Messages' ? this.messageItem : this.$route.name === 'Voicemails' ? this.voicemailItem : this.callItem
    },
    filterNumber() {
      return !this.actionsMenuItem ? '' : this.$route.name === 'Messages' ? this.actionsMenuItem.num : (this.actionsMenuItem.direction === 'in' ? this.actionsMenuItem.ani : this.actionsMenuItem.dnis)
    },
    isBlocked () {
      return this.blockList.findIndex(e => e.number === this.filterNumber) > -1
    },
    isArchive () {
      return this.$route.name === 'Archive'
    }
  },
  watch: {
    actionsMenuItem: {
      deep: true,
      handler () {
        this.checkSpamNumber()
      }
    },
    deleteConversationDialog() {
      this.deleteConversationCheckbox = false
    }
  },
  created () {
    this.checkSpamNumber()
    this.getBlockList()
  },
  methods: {
    ...mapActions('numbers', [
      'blockNumber',
      'unblockNumber',
      'getBlockedNumbers',
      'saveSpamNumber',
      'deleteSpamNumber',
      'markSpamNumber',
      'deleteNumber',
      'deleteChatByNumber',
      'isSpamNumber',
      'archiveNumber'
    ]),
    ...mapActions('calls', ['deleteVoicemail']),
    ...mapActions(['showAlert']),
    toggleDeleteDialog() {
      if (this.$route.name === 'Messages') {
        this.deleteConversationDialog = !this.deleteConversationDialog
        return
      }
      this.deleteDialog = !this.deleteDialog
    },
    async numberBlock() {
      try {
        this.loading = true
        await this.blockNumber({ phone_number: this.filterNumber })

        this.showAlert({ message: 'Number was blocked successfully' })
        await this.getBlockList()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loading = false
        this.blockDialog = false
      }
    },
    async numberUnblock() {
      try {
        this.loading = true
        const blockedNumber = this.blockList.find(e => e.number === this.filterNumber)
        if (!blockedNumber) return
        await this.unblockNumber(blockedNumber.uuid)

        this.showAlert({ message: 'Number was unblocked successfully' })
        await this.getBlockList()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loading = false
        this.blockDialog = false
      }
    },
    async checkSpamNumber() {
      try {
        if (!this.actionsMenuItem) return
        const number = this.$route.name === 'Messages' ? this.actionsMenuItem.num : (this.actionsMenuItem.direction === 'out' ? this.actionsMenuItem.dnis : this.actionsMenuItem.ani)
        if (!number) return
        const data = await this.isSpamNumber(number)
        this.isSpam = !!data.uuid
      } catch (err) {
        throw new Error(err)
      }
    },
    async getBlockList () {
      try {
        const data = await this.getBlockedNumbers()
        this.blockList = data
      } catch (err) {
        throw new Error(err)
      }
    },
    async numberSpam() {
      try {
        this.loading = true
        const data = this.isSpam
          ? await this.deleteSpamNumber(this.filterNumber)
          : await this.saveSpamNumber({ phone_number: this.filterNumber })
        // const data = await this.markSpamNumber(this.actionsMenuItem)

        this.showAlert({ message: data.success })
        this.checkSpamNumber()
        this.$emit('reload')
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loading = false
        this.spamDialog = false
      }
    },
    async removeChat() {
      try {
        this.loading = true
        const data = await this.deleteChatByNumber(this.actionsMenuItem.num)

        this.showAlert({ message: 'The chat has been deleted successfully' })
        this.$emit('removedChat')
        this.$emit('reload')
        this.deleteConversationDialog = false
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async removeNumber() {
      try {
        this.loading = true
        // console.log(this.actionsMenuItem, 'actionsMenuItemactionsMenuItem')
        const data = await this.deleteNumber(this.actionsMenuItem)

        this.showAlert({ message: 'The call has been deleted successfully' })
        this.$emit('reload')
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loading = false
        this.deleteDialog = false
      }
    },
    async removeVoicemail() {
      try {
        this.loading = true
        // console.log(this.actionsMenuItem, 'actionsMenuItemactionsMenuItem')
        const data = await this.deleteVoicemail(this.actionsMenuItem.uuid)

        this.showAlert({ message: 'The voicemail has been deleted successfully' })
        this.$emit('reload')
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loading = false
        this.deleteDialog = false
      }
    },
    async markArchiveNumber () {
      try {
        this.loading = true
        const type = this.$route.name === 'Messages' ? 'sms' : this.$route.name === 'Voicemails' ? 'voicemail' : 'call'
        console.log(this.actionsMenuItem)
        const data = await this.archiveNumber({
          ...this.actionsMenuItem,
          trigger: !this.isArchive,
          _type: type
        })

        this.showAlert({ message: `The ${type} has been ${this.isArchive ? 'removed from archive' : 'archived'} successfully` })
        this.$emit('reload')
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loading = false
        this.archiveDialog = false
      }
    },
    sendPeople() {
      this.$store.dispatch('showPeopleAction', true)
    }
  }
}
</script>

<style lang="scss">
.v-btn {
  text-transform: capitalize;
}

.menuList {
  width: 172px;
  height: 260px;
  .v-list-item__title {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
}

.v-list-item__title {
  white-space: normal;
  line-break: auto;
}
</style>
